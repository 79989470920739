import React, { useEffect, useCallback, useState } from 'react'
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import Footer from '../components/footer'
import Seo from '../components/seo'
import Nav from '../components/nav'

const HomePage = ({data}) => {

  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true ,
  }, [Autoplay()]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        scrollPrev();
      } else if (e.key === 'ArrowRight') {
        scrollNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollPrev, scrollNext]);

  const entry = data.markdownRemark
  //const image = getImage(entry.frontmatter.image)
  const side_image = getImage(entry.frontmatter.side_image)

	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />

      <div className="w-full relative">
        <button className="z-30 absolute top-1/2 -translate-y-1/2 left-6 md:left-12 cursor-pointer text-white hover:text-white/50 disabled:hover:text-white/25 disabled:opacity-25" onClick={scrollPrev}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
        </button>
        <button className="z-30 absolute top-1/2 -translate-y-1/2 right-6 md:right-12 cursor-pointer text-white hover:text-white/50 disabled:hover:text-white/25 disabled:opacity-25" onClick={scrollNext}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
          </svg>
        </button>
        <div className="relative">
          <div ref={emblaRef}>
            <div className="flex">
              {entry.frontmatter.banner.map((entry, i) => {
                const banner_image = getImage(entry.image)
                return (
                  <div key={`b_${i}`} className="w-full py-24 sm:py-48 font-verdana flex-grow-0 flex-shrink-0 basis-full relative">
                    <>
                      <div className="relative z-30 flex items-center justify-center text-center w-full h-full">
                        <div className="w-10/12 lg:w-2/3">
                          <p className="font-bold text-lg text-white mb-3 opacity-75">{entry.heading}</p>
                          <h1 className="font-bold text-4xl lg:text-6xl text-white leading-none">{entry.text}</h1>
                        </div>
                      </div>
                      <div className="absolute -inset-0 z-20 w-full h-full bg-black opacity-25" /> 
                      <div className="absolute -inset-0 z-10 w-full h-full">
                        <GatsbyImage className="w-full h-full object-cover" image={banner_image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
                      </div>
                    </>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-12 bg-light-grey">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-24 font-verdana prose-md lg:prose-lg text-center">
            {entry.frontmatter.why.map((entry, i) => {
              return (
                <div key={`why_${i}`}>
                  <figure>
                    <img src={`/images/uploads/${entry.image}`} alt={entry.heading} className="mx-auto w-24 h-24" />
                  </figure>
                  <h3 className="font-bold">{entry.heading}</h3>
                  <p>{entry.text}</p>
                </div>
              )})}
          </div>
        </div>
      </div>

      <div className="w-full pt-12 pb-12">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-lg">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="font-verdana text-xl font-bold text-white mt-6 bg-red py-3 px-5 inline-block">
                <Link to="/contact/">Request Quote <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
            <div className="w-full sm:w-4/12 pt-12 sm:pt-0">
              {side_image !== null && 
              <figure className="mb-12">
                <GatsbyImage image={side_image} alt={entry.frontmatter.side_heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "contain" }} />
              </figure>
              }
              {entry.frontmatter.side_heading !== null &&
              <div className="font-verdana text-blue font-bold text-2xl">
                <h3>{entry.frontmatter.side_heading}</h3>
              </div>
              }
              {entry.frontmatter.side_text !== null && 
              <div className="font-verdana prose prose-md lg:prose-lg mt-6">
                <ul>
                {entry.frontmatter.side_text.map((entry, i) => {
                  return (
                    <li key={`side_text_${i}`}>{entry.text}</li>
                  )
                })}
                </ul>
              </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-24 bg-grey font-verdana">
        <div className="w-10/12 mx-auto max-w-screen-3xl">
          <div className="flex flex-col md:flex-row justify-center items-center text-center gap-24">
            <div className="w-full md:w-2/3">
              <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-dark-grey prose-h3:text-dark-grey prose-p:text-dark-grey/75 prose-a:text-blue prose-strong:text-dark-grey">
                <h2>{entry.frontmatter.reviews_heading}</h2>
                <p>{entry.frontmatter.reviews_text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24 bg-grey font-verdana">
        <div className="w-10/12 mx-auto max-w-screen-3xl">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="bg-white p-6 flex items-center justify-center text-center">
              <div>
                <div>
                  <span className="text-black text-3xl font-bold leading-snug mb-1 block">Google Rating</span>
                  <span className="text-yellow text-3xl font-bold leading-snug mb-1 block flex justify-center items-center space-x-3">
                    <span>4.7</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span>
                  </span>
                  <span className="text-grey-9 text-sm leading-snug mb-3 block">based on 33 reviews</span>
                </div>
                <div>
                  <a href="https://search.google.com/local/reviews?placeid=ChIJJ7EcH9yHMW0Rkmj6duYAVDU" target="_blank" className="text-green text-base font-bold border-b-2 border-green" title="More google reviews" rel="noopener nofollow">More reviews +</a>
                </div>
              </div>
            </div>
              
            {entry.frontmatter.reviews.map((entry, i) => {
              let excerpt = entry.quote.length > 100 ? entry.quote.substring(0, 100) + "..." : entry.quote;
              return (
                <div key={entry.author} className="bg-white p-6">
                  <div className="text-lg space-x-3 inline-block mt-1 mb-3">
                    <span className="text-yellow">&#9733;</span>
                    <span className="text-yellow">&#9733;</span>
                    <span className="text-yellow">&#9733;</span>
                    <span className="text-yellow">&#9733;</span>
                    <span className="text-yellow">&#9733;</span>
                  </div>
                  <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-blue prose-h3:text-dark-grey prose-p:text-dark-grey/75 prose-a:text-blue prose-strong:text-dark-grey">
                    <p>&#8220;{excerpt}&#8221;</p>
                  </div>
                  <p className="mt-6 font-bold text-blue">&mdash; {entry.author} <small className="text-grey-9 font-normal">({entry.time_ago})</small> <StaticImage style={{ display: "inline-block", width: "20px" }} src="../images/google_sm.png" alt="" /></p>
                </div>
              )}
            )}

          </div>
        </div>
      </div>

      <Footer />
    </div>
	)
}

export default HomePage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
        banner {
          image {
            childImageSharp {
              gatsbyImageData(width: 1024, height: 768)
            }
          }
          heading
          text
        }
				heading
        tagline
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        side_image {
					childImageSharp {
            gatsbyImageData(width: 640)
          }
				}
        side_heading
        side_text {
          text
        }
        quote
        author
        why {
          image
          heading
          text
        }
        reviews_heading
        reviews_text
        reviews {
          quote
          author
          time_ago
        }
			}
			fields {
				slug
			}
      html
		}
	}
`